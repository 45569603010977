import React from 'react';
import { Link } from 'react-router-dom';
import CourseGirl from "../../assets/audio/images/coursegirls.jpg";
import NavbarAll from '../Navbar/NavbarAll';

const CourseGirls = () => {
  return (
    <>
    <NavbarAll />
    <div>
      {/* Hero Section */}
      <section className="w-100 pt-80 pb-70 black-layer position-relative">
        <div
          className="fixed-bg"
          style={{
            backgroundImage: `url(${CourseGirl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
        <div className="container">
          <div className="page-title-wrap text-center w-100">
            <div className="page-title-inner d-inline-block">
              <h1 className="mb-0 text-white text-5xl font-bold">33 Lessons for Every Muslim</h1>
              <p className="text-white text-lg mt-2">Register for this course now</p>
            </div>
          </div>
        </div>
      </section>

      {/* Event Details Section */}
      <section style={{ width: '100%', padding: '20px 0' }}>
        <div
          style={{
            maxWidth: '1100px',
            margin: '0 auto',
            padding: '20px',
            backgroundColor: '#fff',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '16px',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',
          }}
        >
          {/* Image Card */}
          <div
            style={{
              flex: '1',
              minWidth: '300px',
              maxWidth: '400px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '500px',
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img
                src={CourseGirl}
                alt="Course for Girls"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>
          </div>

          {/* Event Details */}
          <div
            style={{
              flex: '2',
              minWidth: '300px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h2 style={{ fontSize: '32px', fontWeight: 'bold', color: '#2563eb', marginBottom: '20px' }}>33 Lessons for Every Muslim - Certificate Course for Girls</h2>
            <p style={{ color: '#4b5563', marginBottom: '10px' }}><strong>📅 Start Date:</strong> After Eid al-Fitr</p>
            <p style={{ color: '#4b5563', marginBottom: '20px' }}><strong>📍 Location:</strong> Masjid Zakaria, 109 26th Street, DHA Phase 8, Karachi</p>

            <p style={{ color: '#374151', marginBottom: '20px' }}>
              Assalamu Alaikum WRB, <br />
              Embark on a spiritual journey with our certificate course designed exclusively for girls aged 9 to 19. Gain knowledge in core Islamic teachings and build a stronger connection to your faith.
            </p>

            <div style={{ marginBottom: '20px' }}>
              <h3 style={{ fontWeight: '600', color: '#9333ea' }}>📚 What You Will Learn:</h3>
              <ul style={{ paddingLeft: '20px', color: '#1f2937' }}>
                <li>Juzz Amma Hifz & Tafseer</li>
                <li>Selected Chapters from Sahih al-Bukhari</li>
                <li>Aqeedah (Beliefs of Islam)</li>
                <li>Tarbiah (Character Building)</li>
              </ul>
            </div>

            <p style={{ color: '#f97316', marginBottom: '20px' }}>
              📖 Students will have the opportunity to deepen their knowledge, strengthen their iman, and receive a certificate upon successful completion.
            </p>

            {/* Register Button */}
            <div style={{ marginTop: 'auto', textAlign: 'center' }}>
              <Link
                to="https://forms.gle/xzwhJm5TGFffwxDT7"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'inline-block',
                  padding: '12px 24px',
                  background: 'linear-gradient(to right, #3b82f6, #6366f1)',
                  color: '#fff',
                  fontWeight: '600',
                  borderRadius: '8px',
                  textDecoration: 'none',
                  transition: 'transform 0.3s ease',
                }}
                onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'}
                onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
              >
                Register Now
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

export default CourseGirls;
