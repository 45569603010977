import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactPlayer from 'react-player';
import NavbarAll from "../Navbar/NavbarAll"
const BayanPage = () => {
  const { id } = useParams();
  const [bayan, setBayan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBayan = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-recording/${id}`);
        setBayan(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching bayan:', err);
        setError('Failed to load bayan');
        setLoading(false);
      }
    };

    fetchBayan();
  }, [id]);

  if (loading) return <p>Loading bayan...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            backgroundColor: "#fff",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <h2 style={{ color: "GrayText" }}>{bayan.title}</h2>
          <p style={{ color: "GrayText" }}>{bayan.date}</p>
          <p style={{ color: "GrayText" }}>مولانا محمد عابد صاحب</p>
          <ReactPlayer
            url={bayan.downloadURL}
            controls
            width="100%"
            height="50px"
          />
        </div>
      </div>
    </>
  );
  
};

export default BayanPage;
