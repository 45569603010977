import React, { useState, useEffect } from "react";
import { 
  BrowserRouter, 
  Routes, 
  Route, 
  Navigate, 
  useLocation 
} from "react-router-dom";
import { initializeFirebase } from "./initializeFirebase";
import HomeComponent from "./Components/HomeComponent/home";
import HazratBayanComponent from "./Components/HazratBayanComponent";
import BiographyComponent from "./Components/BiographyComponent/Biography";
import AskHazratComponent from "./Components/AskHazratComponent/AskHazrat";
import NaatComponent from "./Components/NaatComponent/Naat";
import Services from "./Components/Services2/Services";
import HazratBayan from "./Components/HazratBayan/HazratBayan";
import AddRecordingModal from "./Components/HazratBayanComponent/AddBayanComponent/AddBayanFriday";
import MuftiTaqi from "./Components/MuftiTaqiBayan/MuftiTaqi";
import AdminLogin from "./Components/LoginforAdmin/Login";
import UpdateJamaatTimings from "./Components/PrayerTimeComponent/UpdateJamatTimeAdmin";
import Footer1 from "./Components/FooterComponent/Footer1";
import Activities from "./Components/Activities/Activities";
import Blog from "./Components/Blog/Blog";
import Contact from "./Components/Contact/Contact";
import FridayBayanComponent from "./Components/FridayBayanComponent/FridayBayan";
import BayanPage from "./Components/FridayBayanComponent/BayanPage";
import SundayBayanComponent from "./Components/SundayBayanComponent/SundayBayan";
import ServicesDetailFuneral from "./Components/Services2/ServicesDetailFuneral";
import ServicesDetailQuranClasses from "./Components/Services2/ServicesDetailQuranClasses";
import ServicesDetailHelpingPoor from "./Components/Services2/ServicesDetailHelpingPoor";
import ServicesDetailMosqueDev from "./Components/Services2/ServicesDetailMosqueDev";
import AddBayanMorningDars from "./Components/HazratBayanComponent/AddBayanComponent/AddBayanMorningDars";
import UpdateandDeleteFridayBayan from "./Components/HazratBayanComponent/AddBayanComponent/UpdateandDeleteFridayBayan";
import UpdateandDeleteSundayBayan from "./Components/HazratBayanComponent/AddBayanComponent/UpdateandDeleteSundayBayan";
import EventDetail from "./Components/EventsComponent/EventDetail";
import HomePageEvents from "./Components/EventsComponent/HomePageEvents";
import CourseGirls from "./Components/EventsComponent/CourseGirls";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [value, setValue] = useState("1");

  const handleLogin = () => setIsLoggedIn(true);

  useEffect(() => {
    initializeFirebase(); // Initialize Firebase and request FCM token
  }, []);

  return (
    <BrowserRouter>
      <AppContent 
        isLoggedIn={isLoggedIn} 
        handleLogin={handleLogin} 
        value={value} 
        setValue={setValue} 
      />
    </BrowserRouter>
  );
}

function AppContent({ isLoggedIn, handleLogin, value, setValue }) {
  const location = useLocation();

  // Define routes where the footer should be hidden
  const hideFooterRoutes = ['/bayan'];
  const shouldHideFooter = hideFooterRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeComponent setValue={setValue} value={value} />} />
        <Route path="/update-jamat" element={isLoggedIn ? <UpdateJamaatTimings /> : <Navigate to="/login" />} />
        <Route path="/update-delete-morningfridaybayan" element={isLoggedIn ? <UpdateandDeleteFridayBayan /> : <Navigate to="/login" />} />
        <Route path="/update-delete-morningSundaybayan" element={isLoggedIn ? <UpdateandDeleteSundayBayan /> : <Navigate to="/login" />} />
        <Route path="/login" element={<AdminLogin onLogin={handleLogin} />} />
        <Route path="/moulanaabdulsattar" element={<HazratBayan />} />
        <Route path="/muftitaqi" element={<MuftiTaqi />} />
        <Route path="/hazrat" element={<HazratBayanComponent setValue={setValue} value={value} />} />
        <Route path="/fridaybayan" element={<FridayBayanComponent />} />
        <Route path="/sundaybayan" element={<SundayBayanComponent />} />
        <Route path="/bayan/:id" element={<BayanPage />} />
        <Route path="/seerat" element={<BiographyComponent />} />
        <Route path="/about" element={<AskHazratComponent />} />
        <Route path="/naat" element={<NaatComponent />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services-detail-funeral" element={<ServicesDetailFuneral />} />
        <Route path="/services-detail-quranclasses" element={<ServicesDetailQuranClasses />} />
        <Route path="/services-detail-helpingpoor" element={<ServicesDetailHelpingPoor />} />
        <Route path="/services-detail-mosquedev" element={<ServicesDetailMosqueDev />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/newbayan" element={isLoggedIn ? <AddRecordingModal /> : <Navigate to="/login" />} />
        <Route path="/newbayanmorning" element={isLoggedIn ? <AddBayanMorningDars /> : <Navigate to="/login" />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/iftar-o-clock-2.0" element={<EventDetail />} />
        <Route path="/course-33-lessons" element={<CourseGirls />} />
        <Route path="/home-page-events" element={<HomePageEvents />} />
      </Routes>

      {/* Conditionally render the footer */}
      {!shouldHideFooter && <Footer1 />}
    </>
  );
}

export default App;

