import React from 'react'
import { useNavigate } from 'react-router-dom'

const HomePageEvents = () => {
    const navigate = useNavigate()
  const openwidnwow = () => {
    navigate('/iftar-o-clock-2.0')
    window.scrollTo(0, 0)
  }
    return (
        <div>
            <section style={{ background: 'linear-gradient(135deg, #000, #1C1C1C)', padding: '20px 0' }}>
                <div className="w-100 pt-90 pb-80 position-relative">
                    <div className="container">
                        <div className="sec-title text-center w-100">
                            <div className="sec-title-inner d-inline-block">
                                <h2 style={{ color: "#FFD700", fontSize: '2.5rem' }} className="mb-0">Events</h2>
                                <p className="mb-0" style={{ color: '#B8860B' }}>Join our upcoming community events</p>
                            </div>
                        </div>
                        <div className="event-wrap res-row w-100">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-lg-4">
                                    <div className="event-box w-100 position-relative" style={{ boxShadow: '0 4px 8px rgba(255, 255, 255, 0.1)', borderRadius: '10px', overflow: 'hidden', background: '#2B2B2B' }}>
                                        <div className="event-img overflow-hidden position-relative w-100">
                                            <img style={{ marginTop: '15px' }} className="img-fluid w-100" src="https://via.placeholder.com/300" alt="Event" />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "2px",
                                                    left: "0px",
                                                    backgroundColor: "#FF0000",
                                                    color: "white",
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "0.9rem",
                                                }}
                                            >
                                                <i className="fas fa-calendar-alt" style={{ marginRight: "5px" }}></i> Upcoming
                                            </div>
                                        </div>
                                        <div
                                            className="event-info pat-bg gray-layer opc85 position-relative back-blend-multiply w-100"
                                            style={{ background: '#1A1A1A', padding: '20px' }}
                                        >
                                            <span
                                                style={{ color: "#FF6347", fontWeight: 'bold' }}
                                                className="event-loc d-block thm-clr"
                                            >
                                                <i className="fas fa-map-marker-alt"></i> Zakaria Masjid, DHA Phase 8
                                            </span>
                                            <h3 className="mb-0">
                                                <a href="event-detail.html" title="" style={{ color: '#FF4500' }}>
                                                    Iftar o'Clock 2.0
                                                </a>
                                            </h3>

                                            <button
                                                style={{
                                                    marginTop: "10px",
                                                    backgroundColor: "#8B0000",
                                                    color: "white",
                                                    padding: "10px 20px",
                                                    borderRadius: "8px",
                                                    cursor: 'pointer',
                                                    transition: 'background 0.3s',
                                                }}
                                                onClick={openwidnwow}
                                            >
                                                Register Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomePageEvents
