import React from "react";
import { useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import ReactPlayer from "react-player";
import Mosqueimg from "../../assets/audio/images/mosqueaudio.jpg"
import IosShareIcon from '@mui/icons-material/IosShare';


import { Link } from "react-router-dom";
import NavbarAll from '../NavbarAll/NavbarAllFiles';
const FridayBayanComponent = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [preloaderVisible, setPreloaderVisible] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const bayansPerPage = 10;      
    const [fridayBayans, setFridayBayans] = useState([]);
    const websiteUrl = "https://www.zakariamasjid.com";
    const handleShare = (bayan) => {
      const shareUrl = `${websiteUrl}/bayan/${bayan.id}`;
  
      if (navigator.share) {
        navigator.share({
          title: bayan.title,
          date: bayan.date,
          text: `Listen to this bayan: ${bayan.title} ${" "} ${bayan.date}`,
          url: shareUrl,
        })
        .then(() => console.log("Bayan shared successfully!"))
        .catch((error) => console.error("Error sharing bayan:", error));
      } else {
        alert("Sharing is not supported in this browser.");
      }
    };
    const [bayantCount, setBayantCount] = useState(0);
    useEffect(() => {
      const fetchFridayBayans = async () => {
        console.log(preloaderVisible)
        try {
          const response1 = await axios.get(`${apiUrl}/get-recordings-count/friday`);
          setBayantCount(response1.data.bayantCount);
          
          // Fetch Friday Bayans
          const response = await axios.get(`${apiUrl}/get-recordings/friday`, {
            params: {
              page: pageNumber // Make sure pageNumber is defined and accessible here
            }
          });
          setFridayBayans(response.data);
          setPreloaderVisible(false);
          // console.log("Fetched Friday Bayans successfully:", response.data);
        } catch (error) {
          setPreloaderVisible(false);
          console.error('Error fetching Friday bayans:', error.message);
        }
      };
    
      fetchFridayBayans();
    }, [apiUrl, pageNumber,preloaderVisible]); // Add dependencies to useEffect

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pageNumber]);    
    // Function to handle pagination
    const paginate = pageNumber => {
      setPreloaderVisible(true)
      setPageNumber(pageNumber);
    }
  return (
    <>
    <Helmet>
                <meta
                    name="description"
                    content="Explore the latest Friday bayans from our mosque. Listen to inspiring bayans delivered weekly, available for easy accessibility on our website."
                />
                <title>Latest Friday Bayans | Mosque Audio</title>
            </Helmet>
            {preloaderVisible && (
        <div className="loader-overlay">
          <div className="spinner"></div>
          <p style={{color:"black"}}>Loading Bayanats...</p>
        </div>
      )}
    <NavbarAll />
     <section>
                <div className="w-100 pt-80 black-layer pb-70 opc65 position-relative">
                    <div className="fixed-bg" style={{backgroundImage:`url(${Mosqueimg})`}}></div>
                    <div className="container">
                        <div className="page-title-wrap text-center w-100">
                            <div className="page-title-inner d-inline-block">
                                <h1 className="mb-0">Listen To Bayan</h1>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/" title="Home">Masjid - Home</Link></li>
                                    <li className="breadcrumb-item active">Audio Listening</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
            <div className="w-100 pt-120 pb-250 position-relative">
        <img
          className="sec-botm-rgt-mckp img-fluid position-absolute"
          src="images1/sec-botm-mckp.png"
          alt="Sec Bottom Mockup"
        />
        <div className="container">
      <div className="plyr v4 w-100">
        {fridayBayans.map((bayan, index) => (
          <div
            key={index}
            className="audio-container"
            style={{
              border: "2px solid black",
              backgroundColor: "white",
              color: "black",
              marginBottom: "15px",
            }}
          >
            <div className="audio-info" style={{ display: "flex", alignItems: "center" }}>
              <p
                className="audio-title"
                style={{ color: "black", fontWeight: "bold", fontSize: "16px" }}
              >
                Topic: {bayan.title}
              </p>
              <p
                className="audio-title"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginLeft: "8px",
                }}
              >
                Date: {bayan.date}
              </p>
            </div>
            <ReactPlayer url={bayan.downloadURL} controls width="100%" height="50px" />
            <button
  onClick={() => handleShare(bayan)}
  style={{
    marginLeft: "auto",
    padding: "8px 15px",
    backgroundColor: "#000000",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s ease",
  }}
>
  <IosShareIcon />
</button>

          </div>
        ))}
      </div>
    </div>
                    
                    {fridayBayans.length > 0 && (
  <ul className="pagination" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
    <li className="page-item" style={{ backgroundColor: "black", marginRight: "45px" }}>
      <button style={{ backgroundColor: "black", color: "white" }}
        className="page-link"
        onClick={() => paginate(pageNumber - 1)} // Go to the previous page
        disabled={pageNumber === 1} // Disable if on the first page
      >
        Previous
      </button>
    </li>
    {Array(Math.ceil(bayantCount / bayansPerPage)).fill().map((_, index) => (
      <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`} style={{ marginLeft: "5px" }}>
        <button style={{ backgroundColor: "black", color: "white" }} onClick={() => paginate(index + 1)} className="page-link">{index + 1}</button>
      </li>
    ))}
    <li className="page-item" style={{ marginLeft: "5px" }}>
      <button style={{ backgroundColor: "black", color: "white" }}
        className="page-link"
        onClick={() => paginate(pageNumber + 1)} // Go to the next page
        disabled={pageNumber === Math.ceil(bayantCount / bayansPerPage)} // Disable if on the last page
      >
        Next
      </button>
    </li>
  </ul>
)}
                </div>
                
               
            </section>
            
    </>
  );
};

export default FridayBayanComponent;
